* {
    overscroll-behavior: contain; 
}

.cardStretchInfo {
    background-color: #ecf6ff;
    margin: 5px;
    padding: 5px;
}

.cardStretch {
    /*display: none;*/
}

.cardStretchAction {
    float: right;
    margin-right: 3px;
    margin-top: -28px;
    background-color: white;
    padding: 2px 15px;
    border: 1px #e4e4e4 solid;
}
