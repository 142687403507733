
.details__content p+p {
    
}

.details__content ol,  .details__content ul, .details__content li, .details__content p {
    list-style: disc;
    color: #1f2428;
    font-size: 15px;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: normal;
    line-height: 1.5;
}


.details__content ol, .details__content ul {
    padding-left: 2rem !important;
}

.details__content p:after {
    content:"";
    display:inline-block;
    width: 0;
}

@media (max-width: 767.98px) {
    
    .details__content ol, .details__content ul, .details__content li, .details__content p{
        font-size: 16px !important;
        margin-left: 15px;
        margin-right: 15px;
    }
}
