.no-selected {
    background-color: #0a6ece;
    opacity: 0.6; 
}

.cont-btn-language {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
}
.btn-language {
    width: inherit;
    margin-top: inherit;
    margin-left: 5px;
    margin-right: 5px;
}

@media (max-width: 440px) {
    .cont-btn-language {
        flex-wrap: wrap;
    }
    .btn-language {
        width: auto !important;
        margin: 2px;
        padding: 0 10px !important;
    }
}