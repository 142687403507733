.day-cell-disabled-background {
    background-color: rgb(255, 255, 255);
}
.day-cell-enabled-background {
    background-color: #ffffff !important;
    border: 2px solid white !important;
}
.day-cell-partial-background {
    background-color: #ffe9ce !important;
}

.color-isPast {
    background-color: white !important;
}

.color-enabled {
    color: rgb(255, 255, 255);
}


.color-partial {
    color: #ffe9ce;
}
.color-disable{
    color:black;
}
