.container-discountcode {
    /*max-width: 400px;*/
    margin: 10px 0;
    padding: 10px;
    background-color: #f4f4f4;
    text-align: center;
}

div > .container-discountcode {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btn-code {
    width: inherit;
    margin-left: 5px;
    margin-right: 5px;
}

h5 {
    margin-top: 10px;
    color: #333;
}